import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppRoute } from './app.route.const';
import loadable from '@loadable/component';

const FAQPage = loadable(() => import('src/pages-all/faq/faq.page'));
const PartnerPage = loadable(() => import('src/pages-all/partner/partner.page'));
const AboutUsInstitutPage = loadable(() => import('src/pages-all/about-us-institut/aboutus.page'));
const SetPasswordPage = loadable(() => import('src/pages-all/set-password/setPassword.page'));
const NotImplementedPage = loadable(() => import('src/pages-all/NotImplementedPage'));
const TOSPage = loadable(() => import('src/pages-all/static/tos'));
const ImpressumPage = loadable(() => import('src/pages-all/static/impressum'));
const MyOfferFormPage = loadable(() =>
  import('src/pages-all/offer-form/my-offer-form-page/my-offer-form.page')
);
const InstituteOfferFormPage = loadable(() =>
  import('src/pages-all/offer-form/institute-offer-form-page/institute-offer-form.page')
);
const DataProtectionPage = loadable(() => import('src/pages-all/static/data-protection'));
const NotFoundPage = loadable(() => import('src/pages-all/not-found/not-found-page'));
const TrackedRoute = loadable(() => import('components/TrackedRoute/TrackedRoute'));
const HomePage = loadable(() => import('../pages-all/home/home.page'));
const Comparison = loadable(() => import('src/pages-all/home/comparison.page'));
const RatePage = loadable(() => import('src/pages-all/rate/rate.page'));
const SearchSchoolsPage = loadable(() => import('../pages-all/search/search-schools'));
const SearchOnlineTutorsPage = loadable(() => import('../pages-all/search/search-online'));
const SearchLocalTutorsPage = loadable(() => import('../pages-all/search/search-local'));
const SearchLocalRefugeesPage = loadable(() => import('../pages-all/search/search-refugees'));
const SearchOnlineRefugeesPage = loadable(() => import('../pages-all/search/search-refugees'));
const CitiesPage = loadable(() => import('src/pages-all/search/cities'));
const TutorDetailsPage = loadable(() => import('../pages-all/details/tutor'));
const UserMessagesPage = loadable(() => import('../pages-all/user/threads'));
const MyOfferDetails = loadable(() => import('../pages-all/details/my-offer/MyOfferDetails'));
const AuthorizedRoute = loadable(() => import('../components/AuthorizedRoute/AuthorizedRoute'));
const UserProfilePage = loadable(() => import('../pages-all/user/user-profile/user-profile.page'));
const TutoringInstituteProfilePage = loadable(() =>
  import('src/pages-all/tutoring-institute/tutoring-institute-payments.page')
);
const TutoringInstituteOffersPage = loadable(() =>
  import('src/pages-all/tutoring-institute/tutoring-institute-offers.page')
);
const MyOfferPdfPage = loadable(() =>
  import('src/pages-all/details/my-offer-pdf/my-offer-pdf.page')
);
const BecomeTutorPage = loadable(() => import('src/pages-all/become-tutor/tutor.page'));
const AboutMePage = loadable(() => import('src/pages-all/about-me/about.page'));
const SocialCallPage = loadable(() => import('src/pages-all/call/social/social-call.page'));
const EliteTutorFormPage = loadable(() =>
  import('src/pages-all/offer-form/elitetutor-offer-form/elitetutor-offer-form.page')
);
const UpComingLessonsPage = loadable(() =>
  import('src/pages-all/user/user-profile/up-coming-lessons/UpComingLessons.page')
);
const AvailabilityPage = loadable(() =>
  import('src/pages-all/user/user-profile/availability/availability.page')
);
const IntroductionCallPage = loadable(() =>
  import('src/pages-all/call/introduction/introduction-call.page')
);
const BookingFormPage = loadable(() => import('src/pages-all/booking-form/booking-form.page'));
const SearchEliteTutorsPage = loadable(() => import('../pages-all/search/search-elite'));
const MyPricingPage = loadable(() =>
  import('src/pages-all/user/user-profile/my-pricing/my-pricing.page')
);
const PersonalizedSubscription = loadable(() =>
  import('src/pages-all/user/user-profile/my-pricing/personalized-subscription.page')
);
const MyPricingSuccessPage = loadable(() =>
  import('src/pages-all/user/user-profile/my-pricing/my-pricing-success.page')
);
const BillingPage = loadable(() => import('src/pages-all/user/user-profile/billing/billing.page'));
const EarningsPage = loadable(() =>
  import('src/pages-all/user/user-profile/earnings/earnings.page')
);
const EarnMoney = loadable(() =>
  import('src/pages-all/user/user-profile/earn-money/earn-money.page')
);
const MySubjectsPage = loadable(() =>
  import('src/pages-all/user/user-profile/my-subjects/my-subjects.page')
);
const RefugeeInitiativePage = loadable(() =>
  import('src/pages-all/refugee-initiative/refugee-initiative.page')
);
const TutorspacePlus = loadable(() => import('src/pages-all/home/tutorspace-plus.page'));
const TutorspacePremiumRedirect = loadable(() =>
  import('src/pages-all/home/tutorspace-premium-legacy.page')
);
const TutorspacePremiumTutoring = loadable(() =>
  import('src/pages-all/home/tutorspace-premium-tutoring.page')
);
const TutorspacePremiumBecome = loadable(() =>
  import('src/pages-all/home/tutorspace-premium-become.page')
);
const TutorspacePremiumRegisterAsTutor = loadable(() =>
  import('src/pages-all/register-as-elitetutor/register-as-elitetutor.page')
);
const CreditPurchasePage = loadable(() =>
  import('src/pages-all/credit-purchase/credit-purchase.page')
);
const PatrickNadlerPage = loadable(() =>
  import('src/pages-all/patrick-nadler/patrick-nadler.page')
);
const JobsPage = loadable(() => import('src/pages-all/jobs/jobs.page'));
const NewsPage = loadable(() => import('src/pages-all/news/news.page'));
const PressPage = loadable(() => import('src/pages-all/press/press.page'));

export const AppRouter = () => (
  <Switch>
    <TrackedRoute exact path={AppRoute.Home} component={HomePage} />
    <TrackedRoute exact path={AppRoute.Comparison} component={Comparison} />
    <TrackedRoute
      exact
      path={AppRoute.SearchLocalTutorsPerCity}
      component={SearchLocalTutorsPage}
    />
    <TrackedRoute
      exact
      path={AppRoute.SearchLocalTutorsBySubject}
      component={SearchLocalTutorsPage}
    />
    <TrackedRoute
      exact
      path={AppRoute.SearchLocalTutorsPerCityAndSubject}
      component={SearchLocalTutorsPage}
    />
    <TrackedRoute exact path={AppRoute.SearchOnlineTutors} component={SearchOnlineTutorsPage} />
    <TrackedRoute exact path={AppRoute.SearchEliteTutors} component={SearchEliteTutorsPage} />
    <TrackedRoute exact path={AppRoute.SearchSchools} component={SearchSchoolsPage} />
    <TrackedRoute exact path={AppRoute.SearchRefugeesPerCity} component={SearchLocalRefugeesPage} />
    <TrackedRoute exact path={AppRoute.SearchRefugeesOnline} component={SearchOnlineRefugeesPage} />
    <TrackedRoute exact path={AppRoute.Cities} component={CitiesPage} />
    <TrackedRoute exact path={AppRoute.TutorDetails} component={TutorDetailsPage} />
    <TrackedRoute exact path={AppRoute.SchoolDetails} component={TutorDetailsPage} />
    <TrackedRoute exact path={AppRoute.EliteTutorDetails} component={TutorDetailsPage} />
    <TrackedRoute exact path={AppRoute.RefugeeTutorDetails} component={TutorDetailsPage} />
    <TrackedRoute exact path={AppRoute.FAQ} component={FAQPage} />
    <AuthorizedRoute exact path={AppRoute.PublishOffer} component={MyOfferFormPage} />
    <AuthorizedRoute exact path={AppRoute.EditOffer} component={MyOfferFormPage} />
    <AuthorizedRoute exact path={AppRoute.AddInstituteOffer} component={InstituteOfferFormPage} />
    <AuthorizedRoute exact path={AppRoute.EditInstituteOffer} component={InstituteOfferFormPage} />
    <AuthorizedRoute exact path={AppRoute.MyOfferRequests} component={UserMessagesPage} />
    <AuthorizedRoute exact path={AppRoute.MyOffer} component={MyOfferDetails} />
    <AuthorizedRoute exact path={AppRoute.MyOfferPdf} component={MyOfferPdfPage} />
    <AuthorizedRoute exact path={AppRoute.EliteTutorOffer} component={EliteTutorFormPage} />
    <AuthorizedRoute exact path={AppRoute.UserProfile} component={UserProfilePage} />
    <AuthorizedRoute
      exact
      path={AppRoute.InstituteSettings}
      component={TutoringInstituteProfilePage}
    />
    <AuthorizedRoute
      exact
      path={AppRoute.InstituteOffers}
      component={TutoringInstituteOffersPage}
    />
    <AuthorizedRoute exact path={AppRoute.SocialCall} component={SocialCallPage} />
    <AuthorizedRoute exact path={AppRoute.UnitOverview} component={UpComingLessonsPage} />
    <AuthorizedRoute exact path={AppRoute.Availability} component={AvailabilityPage} />
    <AuthorizedRoute exact path={AppRoute.IntroductionCall} component={IntroductionCallPage} />
    <AuthorizedRoute exact path={AppRoute.MyPricing} component={MyPricingPage} />
    <AuthorizedRoute exact path={AppRoute.MyPricingSuccess} component={MyPricingSuccessPage} />
    <AuthorizedRoute exact path={AppRoute.Billing} component={BillingPage} />
    <AuthorizedRoute exact path={AppRoute.Earnings} component={EarningsPage} />
    <AuthorizedRoute exact path={AppRoute.EarnMoney} component={EarnMoney} />
    <AuthorizedRoute exact path={AppRoute.MySubjects} component={MySubjectsPage} />
    <Route exact path={AppRoute.BookEliteTutor} component={BookingFormPage} />
    <TrackedRoute exact path={AppRoute.ForTutorsBecomeTutor} component={BecomeTutorPage} />
    <TrackedRoute exact path={AppRoute.Partners} component={PartnerPage} />
    <TrackedRoute exact path={AppRoute.AboutUs} component={AboutMePage} />
    <TrackedRoute exact path={AppRoute.ForStudentsTeachersNearYou} component={NotImplementedPage} />
    <TrackedRoute exact path={AppRoute.AboutTSHowDoesItWork} component={NotImplementedPage} />
    <TrackedRoute
      exact
      path={AppRoute.AboutTSForTutoringInstitutes}
      component={AboutUsInstitutPage}
    />
    <TrackedRoute
      exact
      path={AppRoute.SupportAndHelpYouAskWeAnswer}
      component={NotImplementedPage}
    />
    <TrackedRoute
      exact
      path={AppRoute.SupportAndHelpYouAskWeAnswer}
      component={NotImplementedPage}
    />
    <TrackedRoute exact path={AppRoute.SupportAndHelpContact} component={NotImplementedPage} />
    <TrackedRoute exact path={AppRoute.FooterImpressum} component={ImpressumPage} />
    <TrackedRoute exact path={AppRoute.FooterTOS} component={TOSPage} />
    <TrackedRoute exact path={AppRoute.FooterDataProtection} component={DataProtectionPage} />
    <TrackedRoute
      exact
      path={AppRoute.ServiceAndAdviceHowDoesItWork}
      component={NotImplementedPage}
    />
    <TrackedRoute
      exact
      path={AppRoute.ServiceAndAdviceHowDoesItWork}
      component={NotImplementedPage}
    />
    <TrackedRoute
      exact
      path={AppRoute.GetStartedNowAdvertiseOffer}
      component={NotImplementedPage}
    />
    <TrackedRoute exact path={AppRoute.SetPassword} component={SetPasswordPage} />
    <TrackedRoute exact path={AppRoute.Rate} component={RatePage} />
    <TrackedRoute exact path={AppRoute.TutorspacePlus} component={TutorspacePlus} />
    <TrackedRoute
      exact
      path={AppRoute.TutorspacePremiumLegacy}
      component={TutorspacePremiumRedirect}
    />
    <TrackedRoute
      exact
      path={AppRoute.TutorspacePremiumTutoring}
      component={TutorspacePremiumTutoring}
    />
    <TrackedRoute
      exact
      path={AppRoute.TutorspacePremiumBecome}
      component={TutorspacePremiumBecome}
    />
    <TrackedRoute
      exact
      path={AppRoute.TutorspacePremiumRegisterAsTutor}
      component={TutorspacePremiumRegisterAsTutor}
    />
    <TrackedRoute
      exact
      path={AppRoute.TutorspacePremiumRegisterAsTutorSteps}
      component={TutorspacePremiumRegisterAsTutor}
    />
    <TrackedRoute exact path={AppRoute.RefugeeInitiative} component={RefugeeInitiativePage} />
    <TrackedRoute exact path={AppRoute.Jobs} component={JobsPage} />
    <TrackedRoute exact path={AppRoute.News} component={NewsPage} />
    <TrackedRoute exact path={AppRoute.Press} component={PressPage} />
    <TrackedRoute exact path={AppRoute.CreditPurchase} component={CreditPurchasePage} />
    <TrackedRoute exact path={AppRoute.PatrickNadler} component={PatrickNadlerPage} />
    <TrackedRoute
      exact
      path={AppRoute.PersonalizedSubscription}
      component={PersonalizedSubscription}
    />
    <Route exact path={AppRoute.NotFound} component={NotFoundPage} />
    <Route component={NotFoundPage} />
  </Switch>
);
