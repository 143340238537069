import { StoreState } from '../../../app/app.reducers';

export const getSubmitEliteApplicationResponseState = (state: StoreState) =>
  state.elitetutor.submitEliteApplicationResponseState;

export const getSubjectApplications = (state: StoreState) =>
  state.elitetutor.subjectApplicationsResponseState;

export const getVacancyList = (state: StoreState) =>
  state.elitetutor.vacancyListResponseState?.vacancies;

export const getVacancyBookingsList = (state: StoreState) =>
  state.elitetutor.vacancyListResponseState?.bookings;

export const getBookingList = (state: StoreState) =>
  state.elitetutor.bookingListResponseState.bookingListResponse;

export const getBookingListType = (state: StoreState) =>
  state.elitetutor.bookingListResponseState.type;

export const getBookingListViewAs = (state: StoreState) =>
  state.elitetutor.bookingListResponseState.viewAs;

export const getBookingListPayload = (state: StoreState) =>
  state.elitetutor.bookingListResponseState.payload;

export const getElitePaymentConfig = (state: StoreState) =>
  state.elitetutor.elitePaymentConfigResponseState;

export const getPersonalizedSubscriptionData = (state: StoreState) =>
  state.elitetutor.personalizedSubscriptionData;

export const getElitePaymentBestPriceList = (state: StoreState) => {
  const plans = state.elitetutor.elitePaymentConfigResponseState?.plans || [];
  const elitePlanBiannually = plans[0];

  return elitePlanBiannually?.tiers;
};

export const getElitePaymentRegularPrice = (state: StoreState) => {
  const plans = state.elitetutor.elitePaymentConfigResponseState?.plans || [];
  const elitePlanMonthly = plans[plans.length - 1];

  return elitePlanMonthly?.tiers[0]?.normalizedPrice;
};

export const getElitePaymentPlans = (state: StoreState) =>
  state.elitetutor.elitePaymentConfigResponseState?.plans;

export const getElitePaymentExamPrepPlan = (state: StoreState) =>
  state.elitetutor.elitePaymentConfigResponseState?.examPrep.plan;

export const getElitePaymentSeekerInfo = (state: StoreState) =>
  state.elitetutor.elitePaymentSeekerInfoResponseState;

export const getEliteseekerCreditEventList = (state: StoreState) =>
  state.elitetutor.eliteseekerCreditEventListResponseState?.results;

export const getElitetutorCreditEventList = (state: StoreState) =>
  state.elitetutor.elitetutorCreditEventListResponseState?.results;

export const getCreditTutor = (state: StoreState) => state.elitetutor.creditTutorResponseState;

export const getCanceledBooking = (state: StoreState) =>
  state.elitetutor.cancelBookingResponseState;

export const getSeekerSubscriptionChangeInfoPreview = (state: StoreState) =>
  state.elitetutor.seekerSubscriptionChangeInfoPreviewResponseState;

export const getElitepaymentCreditPurchase = (state: StoreState) =>
  state.elitetutor.elitepaymentCreditPurchaseResponseState;

export const getBookingCreditInsufficientInfo = (state: StoreState) =>
  state.elitetutor.bookingCreditInsufficientInfo;

export const getEliteratings = (state: StoreState) => state.elitetutor.eliteratingResponseState;
export const getOpenEliteratings = (state: StoreState) =>
  state.elitetutor.eliteratingResponseState.filter((rating) => rating.status === 'open');
