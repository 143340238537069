import { AppRoute } from 'app/app.route.const';
import { translate, translationKeys } from 'logic/translations/translations.service';

export type MenuItem = {
  key: string;
  title: string;
  submenu: Submenu[];
};

type Submenu = {
  title: string;
  url: string;
  description?: string;
};

export enum MenuItemKey {
  Search = 'Search',
  Offer = 'Offer',
  TutorSpace = 'TutorSpace',
}

export const getMenuItems: MenuItem[] = [
  {
    key: MenuItemKey.Search,
    title: translate(translationKeys.header.menuItems.search.title),
    submenu: [
      {
        title: translate(translationKeys.header.menuItems.search.subMenu.comparison.title),
        description: translate(
          translationKeys.header.menuItems.search.subMenu.comparison.description
        ),
        url: AppRoute.Comparison,
      },
      {
        title: translate(translationKeys.header.menuItems.search.subMenu.premium.title),
        description: translate(translationKeys.header.menuItems.search.subMenu.premium.description),
        url: AppRoute.Home,
      },
    ],
  },
  {
    key: MenuItemKey.Offer,
    title: translate(translationKeys.header.menuItems.offer.title),
    submenu: [
      {
        title: translate(translationKeys.header.menuItems.offer.subMenu.private.title),
        description: translate(translationKeys.header.menuItems.offer.subMenu.private.description),
        url: AppRoute.PublishOffer,
      },
      {
        title: translate(translationKeys.header.menuItems.offer.subMenu.premium.title),
        description: translate(translationKeys.header.menuItems.offer.subMenu.premium.description),
        url: AppRoute.TutorspacePremiumBecome,
      },
    ],
  },
  {
    key: MenuItemKey.TutorSpace,
    title: translate(translationKeys.header.menuItems.aboutUs.title),
    submenu: [
      {
        title: translate(translationKeys.header.menuItems.aboutUs.subMenu.aboutUs.title),
        url: AppRoute.AboutUs,
      },
      {
        title: translate(translationKeys.header.menuItems.aboutUs.subMenu.jobs.title),
        url: AppRoute.Jobs,
      },
      {
        title: translate(translationKeys.header.menuItems.aboutUs.subMenu.faq.title),
        url: AppRoute.FAQ,
      },
      {
        title: translate(translationKeys.header.menuItems.aboutUs.subMenu.press.title),
        url: AppRoute.Press,
      },
    ],
  },
];
