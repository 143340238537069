const INSTITUTION_IMAGE = '/images/user-institution.png';
const PARENT_IMAGE = '/images/user-parent.png';
const OTHER_ROLE_IMAGE = '/images/other-role.png';
const SCHOOLBOY_IMAGE = '/images/user-schoolboy.png';
const MAN_IMAGE = '/images/man.png';
const STUDENT_IMAGE = '/images/user-student.png';
const USER_PROFILE_DEFAULT_AVATAR_IMAGE = '/images/user-profile-default-avatar.jpg';
const USER_AVATAR_PLACEHOLDER_IMAGE = '/images/user-avatar-placeholder.jpg';
const LOGO_HP_1 = '/images/hp/logo1.png';
const LOGO_HP_2 = '/images/hp/logo2.png';
const LOGO_HP_3 = '/images/hp/logo3.png';
const TUEV_CERTIFICATION = '/images/hp/TUEV-certification.png';
const APP_STORE = '/images/hp/applestore.png';
const GOOGLE_STORE = '/images/hp/googleplay.png';
const PHONE_1 = '/images/hp/phone1.png';
const PHONE_2 = '/images/hp/phone2.png';
const FEATURE_1 = '/images/hp/feature1.svg';
const FEATURE_2 = '/images/hp/feature2.svg';
const FEATURE_3 = '/images/hp/feature3.svg';
const FEATURE_4 = '/images/hp/feature4.svg';
const QUOTATIONS_PETRA = '/images/hp/quotations/petra.jpg';
const QUOTATIONS_PETRA_ABI = '/images/hp/quotations/petra-abi.jpg';
const QUOTATIONS_KATHRIN = '/images/hp/quotations/kathrin.jpg';
const QUOTATIONS_MARIO = '/images/hp/quotations/mario.jpg';
const QUOTATIONS_SOPHIE = '/images/hp/quotations/sophie.jpg';
const QUOTATIONS_MICHAEL = '/images/hp/quotations/Michael.jpg';
const QUOTATIONS_SABINE = '/images/hp/quotations/sabine.jpg';
const QUOTATIONS_ANJA = '/images/hp/quotations/anja.jpg';
const QUOTATIONS_BIANCA = '/images/hp/quotations/bianca.jpg';
const COMPUTER = '/images/computer.jpg';
const MASTERCARD_LOGO = '/images/tutoring-institute/mastercard-logo.png';
const VISA_LOGO = '/images/tutoring-institute/visa-logo.png';
const SEPA_LOGO = '/images/tutoring-institute/sepa-logo.png';
const NOT_FOUND_404 = '/images/404.svg';
const WOMAN_IMAGE = '/images/woman.png';
const WOMAN_GIRL_POINTING = '/images/woman_girl_pointing.png';
const WOMAN_LAPTOP_SMILING = '/images/premium-become/woman-laptop-smiling.png';
const MAN_LAPTOP_SMILING = '/images/premium-become/man-laptop-smiling.jpg';
const WOMAN_LAPTOP_WAVING = '/images/premium-become/woman-laptop-waving.jpg';
const WOMAN_POINTING_FINGER_YELLOW = '/images/premium-become/woman-pointing-finger-yellow.png';
const FINDING_HELP = '/images/finding_help.jpg';
const FINDING_HELP_BUBBLES = '/images/finding_help_bubbles.png';
const FINDING_HELP_BUBBLES_MOBILE = '/images/finding_help_bubbles_mobile.png';
const FINDING_HELP_WOMAN = '/images/finding_help_woman.jpg';
const TUTORING_LAUGHING = '/images/tutoring_laughing.jpg';
const TUTOR_POINTING_AT_BOARD = '/images/tutor-pointing-at-board.jpg';
const ELITE_TUTOR_CARD_EXAMPLE = '/images/elite-tutor-card-example.png';
const TUTOR_BLACKBOARD_AND_STUDENT_DESK = '/images/tutor-blackboard-and-student-desk.png';
const STARBACKGROUND_WITH_SLIDE = '/images/star_background_with_slide.jpg';
const STARBACKGROUND_WITH_SLIDE_MOBILE = '/images/star_background_with_slide_mobile.jpg';
const BLOG_LAPTOP = '/images/macbook.png';
const INDIVIDUAL_PLAN = '/images/individual-plan.jpg';
const HEADPHONES_CHILD = '/images/headphones-child.jpg';
const SUCCESS_GUY = '/images/success-guy.jpg';
const GUIDELINE_FILE = '/images/guideline_file.png';
const STUTTGARTER_ZEITUNG = '/images/Stuttgarter_Zeitung.png';
const WIRTSCHAFTSWOCHE = '/images/Wirtschafts_Woche.png';
const DIE_REINLANDPFALZ = '/images/Die_Rheinpfalz.png';
const TUTORSPACE_PREMIUM_LOGO = '/images/tutorspace_premium_logo.png';
const SEARCH_SECTION_IMAGE = '/images/search_section_image.png';
const SEARCH_HP_SECTION_IMAGE = '/images/search_hp_section_image.png';
const TANSANIA_CHILD = '/images/tansania_child.jpg';
const TANSANIA_HANDS = '/images/hands.jpg';
const PROBONO_WITH_KIDS = '/images/probono_with_kids.png';
const SWR = '/images/SWR.png';
const NEW = '/images/new.png';
const REVIEW1 = '/images/hp/review1.png';
const REVIEW2 = '/images/hp/review2.png';
const REVIEW3 = '/images/hp/review3.png';
const REVIEW4 = '/images/hp/review4.png';
const TUTOR_REVIEW1 = '/images/premium-become/review1.png';
const TUTOR_REVIEW2 = '/images/premium-become/review2.png';
const UKRAINE_FLAGS = '/images/ukraine-flags.png';
const FIRST_AID_GERMAN = '/images/first-aid-german.png';
const LOGO_DEIN_SPRACHCOACH = '/images/external-logos/logo-dein-sprachcoach.png';
const LOGO_HORIZON_ALPHA = '/images/external-logos/logo-horizon-alpha.png';
const LOGO_STEINBACHPR = '/images/external-logos/logo-steinbachpr.jpeg';
const LOGO_BM_RLP = '/images/external-logos/logo-bm-rlp.png';
const LOGO_MARKT_DE = '/images/external-logos/logo-markt-de.png';
const LOGO_STELLENWERK = '/images/external-logos/logo-stellenwerk.png';
const LOGO_GO_VOLUNTEER = '/images/external-logos/logo-go-volunteer.png';
const LOGO_ALLIANCE_4_UKRAINE = '/images/external-logos/logo-alliance-4-ukraine.png';
const LOGO_PURPOZED = '/images/external-logos/logo-purpozed.png';
const LOGO_FLEXHERO = '/images/external-logos/logo-flexhero.png';
const LOGO_NEBENAN_DE = '/images/external-logos/logo-nebenan-de.png';
const LINKEDIN_MARIA_HASBOLAT = '/images/linkedin/Maria-Hasbolat.jpeg';
const LINKEDIN_PATRICK_NADLER = '/images/linkedin/Patrick-Nadler.jpeg';
const LINKEDIN_CHRISTOPH_JASCHINSKI = '/images/linkedin/Christoph-Jaschinski.png';
const LINKEDIN_FELIX_MICHL = '/images/linkedin/Felix-Michl.png';
const CONTACT_CAROLINE = '/images/premium-become/caroline.png';
const BBB_SCREENSHOT = '/images/register-as-elitetutor/bbb-screenshot.jpg';
const I_AM_SORRY = '/images/register-as-elitetutor/i-am-sorry.jpeg';
const TRUSTPILOT_RATING = '/images/register-as-elitetutor/trustpilot-rating.png';
const PARENTS_WITH_CHILD_1 = '/images/update-payout-info/eltern-mit-kind_1.png';
const PARENTS_WITH_CHILD_2 = '/images/update-payout-info/eltern-mit-kind_2.png';

export {
  PARENT_IMAGE,
  STUDENT_IMAGE,
  SCHOOLBOY_IMAGE,
  INSTITUTION_IMAGE,
  OTHER_ROLE_IMAGE,
  MAN_IMAGE,
  USER_PROFILE_DEFAULT_AVATAR_IMAGE,
  USER_AVATAR_PLACEHOLDER_IMAGE,
  LOGO_HP_1,
  LOGO_HP_2,
  LOGO_HP_3,
  TUEV_CERTIFICATION,
  APP_STORE,
  GOOGLE_STORE,
  PHONE_1,
  PHONE_2,
  FEATURE_1,
  FEATURE_2,
  FEATURE_3,
  FEATURE_4,
  QUOTATIONS_PETRA,
  QUOTATIONS_PETRA_ABI,
  QUOTATIONS_KATHRIN,
  QUOTATIONS_MARIO,
  QUOTATIONS_SOPHIE,
  QUOTATIONS_MICHAEL,
  QUOTATIONS_SABINE,
  QUOTATIONS_ANJA,
  QUOTATIONS_BIANCA,
  COMPUTER,
  MASTERCARD_LOGO,
  VISA_LOGO,
  SEPA_LOGO,
  NOT_FOUND_404,
  WOMAN_IMAGE,
  WOMAN_GIRL_POINTING,
  WOMAN_LAPTOP_SMILING,
  MAN_LAPTOP_SMILING,
  WOMAN_LAPTOP_WAVING,
  WOMAN_POINTING_FINGER_YELLOW,
  FINDING_HELP,
  FINDING_HELP_BUBBLES,
  FINDING_HELP_BUBBLES_MOBILE,
  FINDING_HELP_WOMAN,
  TUTORING_LAUGHING,
  TUTOR_POINTING_AT_BOARD,
  ELITE_TUTOR_CARD_EXAMPLE,
  TUTOR_BLACKBOARD_AND_STUDENT_DESK,
  STARBACKGROUND_WITH_SLIDE,
  STARBACKGROUND_WITH_SLIDE_MOBILE,
  BLOG_LAPTOP,
  INDIVIDUAL_PLAN,
  HEADPHONES_CHILD,
  SUCCESS_GUY,
  GUIDELINE_FILE,
  STUTTGARTER_ZEITUNG,
  WIRTSCHAFTSWOCHE,
  DIE_REINLANDPFALZ,
  TUTORSPACE_PREMIUM_LOGO,
  SEARCH_SECTION_IMAGE,
  TANSANIA_CHILD,
  TANSANIA_HANDS,
  PROBONO_WITH_KIDS,
  SWR,
  NEW,
  REVIEW1,
  REVIEW2,
  REVIEW3,
  REVIEW4,
  TUTOR_REVIEW1,
  TUTOR_REVIEW2,
  SEARCH_HP_SECTION_IMAGE,
  UKRAINE_FLAGS,
  FIRST_AID_GERMAN,
  LOGO_DEIN_SPRACHCOACH,
  LOGO_HORIZON_ALPHA,
  LOGO_STEINBACHPR,
  LOGO_BM_RLP,
  LOGO_MARKT_DE,
  LOGO_STELLENWERK,
  LOGO_GO_VOLUNTEER,
  LOGO_ALLIANCE_4_UKRAINE,
  LOGO_PURPOZED,
  LOGO_FLEXHERO,
  LOGO_NEBENAN_DE,
  LINKEDIN_MARIA_HASBOLAT,
  LINKEDIN_PATRICK_NADLER,
  LINKEDIN_CHRISTOPH_JASCHINSKI,
  LINKEDIN_FELIX_MICHL,
  CONTACT_CAROLINE,
  BBB_SCREENSHOT,
  I_AM_SORRY,
  TRUSTPILOT_RATING,
  PARENTS_WITH_CHILD_1,
  PARENTS_WITH_CHILD_2,
};
